import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import netlifyAuth from './netlifyAuth';
import * as serviceWorker from './serviceWorker';
//import netlifyIdentity from 'netlify-identity-widget';
import App from './App';

/*
window.netlifyIdentity = netlifyIdentity;
window.netlifyAuth = netlifyAuth;
*/

netlifyAuth.init();
//console.log(netlifyAuth.user);

/*
netlifyIdentity.on('init', user => console.log('init', user));
netlifyIdentity.on('login', user => console.log('login', user));
netlifyIdentity.on('logout', () => console.log('Logged out'));
netlifyIdentity.on('error', err => console.error('Error', err));
netlifyIdentity.on('open', () => console.log('Widget opened'));
netlifyIdentity.on('close', () => console.log('Widget closed'));
*/

ReactDOM.render(<App isAuthenticated={netlifyAuth.isAuthenticated}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
