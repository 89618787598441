import netlifyIdentity from 'netlify-identity-widget';

const netlifyAuth = {
  isAuthenticated: false,
  user: null,
  onLogin: null,
  onLogout: null,
  onStateChange: null,
  init(){
    netlifyIdentity.init();
    this.user = netlifyIdentity.currentUser();
    this.isAuthenticated = this.user ? true : false;

    netlifyIdentity.on('login', user => {
      this.isAuthenticated = true;
      this.user = user;
      if (this.onLogin) {
        const onLogin = this.onLogin;
        this.onLogin = null;
        onLogin(user);
      } else if (this.onStateChange)
        this.onStateChange(user);
    });

    netlifyIdentity.on('logout', () => {
      this.isAuthenticated = false;
      this.user = null;
      if (this.onLogout) {
        const onLogout = this.onLogout;
        this.onLogout = null;
        onLogout();
      } else if (this.onStateChange)
        this.onStateChange();

    });
  },
  authenticate(callback) {
    this.onLogin = callback;
    netlifyIdentity.open();
  },
  signout(callback) {
    this.onLogout = callback;
    netlifyIdentity.logout();
  },
  signup(callback) {
    netlifyIdentity.open('signup');
  },
  generateHeaders() {
    const headers = { "Content-Type": "application/json" };
    if (this.user) {
      return this.user.jwt().then((token) => {
        return { ...headers, Authorization: `Bearer ${token}` };
      })
    }
    return Promise.resolve(headers);
  }
};

export default netlifyAuth;
