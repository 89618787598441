import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import { AuthButton } from './App';
import netlifyAuth from './netlifyAuth';
import "./Calender.css";

export function Protected() {
  const user = netlifyAuth.user;
  console.log({ user });
  return (
    <div>
        <ul id="header-menu">
          <li>
            <Link to="/">トップ</Link>
          </li>
          <li>
            <Link to="/protected">マイページ</Link>
          </li>
          <li>
            <AuthButton />
          </li>
        </ul>
      <div>
        <h3>マイページ</h3>
        <b>{user.email}</b>としてログインしています
      </div>
    </div>
  );
}

export class Root extends Component {
  
  render() {
    return(
	<header className="App-header">
        <ul id="header-menu">
          <li>
            <Link to="/">トップ</Link>
          </li>
          <li>
            <Link to="/protected">マイページ</Link>
          </li>
          <li>
            <AuthButton />
          </li>
        </ul>
          <CalenderControl />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <LambdaDemo />
        </header>
    )
  }
}

class LambdaDemo extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, msg: null, id: '' }
  }

  handleClick = api => e => {
    e.preventDefault()

    this.setState({ loading: true })
    netlifyAuth.generateHeaders().then((headers) => {
      fetch("/.netlify/functions/" + api, {
        method: "POST",
        headers,
        body: JSON.stringify({
          text: api
        })
      })
      .then(response => response.json())
      .then(json => this.setState({ loading: false, msg: json.msg }))
    });
  }

  handleChangeId = e => {
    this.setState({ id: e.target.value })
  }

  readAll = () => {
    netlifyAuth.generateHeaders()
    .then((headers) => fetch('/.netlify/functions/schedule-read-all/2020-08', {
      method: 'POST',
      headers
    }))
    .then(response => response.json())
    .then(json => this.setState({ loading: false, msg: JSON.stringify(json) }))
  }

  read = () => {
    netlifyAuth.generateHeaders()
    .then((headers) => fetch(`/.netlify/functions/schedule-read/${this.state.id}`, {
      method: 'POST',
      headers
    }))
    .then(response => response.json())
    .then(json => this.setState({ loading: false, msg: JSON.stringify(json) }))
  }

  render() {
    const { loading, msg } = this.state

    return (
      <p>
        <input value={ this.state.id } onChange={ this.handleChangeId } />
        <button onClick={this.read}>{loading ? "Loading..." : "read"}</button>
        <button onClick={this.readAll}>{loading ? "Loading..." : "readAll"}</button>
        <button onClick={this.handleClick("hello")}>{loading ? "Loading..." : "Call Lambda"}</button>
        <button onClick={this.handleClick("async-dadjoke")}>{loading ? "Loading..." : "Call Async Lambda"}</button>
        <br />
        <span>{msg}</span>
      </p>
    )
  }
}

// http://honeplus.blog50.fc2.com/blog-entry-175.html
function isPromise(obj: any): boolean {
	return obj instanceof Promise || (obj && typeof obj.then === 'function');
}

function fetchSchedule(monthString, callback){
      netlifyAuth.generateHeaders()
      .then((headers) => fetch(`/.netlify/functions/schedule-read-all/${monthString}`, {
        method: 'POST',
        headers
      }))
      .then(response =>	{
	if (response.status !== 200)
	  throw response.json();
	return response.json();
      })
      .then(json => {
	const sch = {};
	json.forEach(v => {
	  sch[v.date] = { checks: JSON.parse(v.teachers), comment: v.comment, comments: v.comments };
	});
	callback(sch);
      }).catch((error) => {
	if (isPromise(error)) {
	  error.then(json => {
	    console.log('error', json.msg)
          })
	} else
          console.log('error', error)
      })
}

export function Calender({month: monthString})
{
    const [year, month] = getYearMonth(monthString);
    const [schedulesCache, updateSchedulesCache] = useState({});
    const schedules = schedulesCache[monthString] ? schedulesCache[monthString] : [];
    if (! schedulesCache[monthString]) {
      console.log(`Fetch month=${monthString}`);
      fetchSchedule(monthString, (sch) => {
	updateSchedulesCache({ ...schedulesCache, [monthString]: sch });
      });
    }
    const cols = [
              {"head": "日",     "dow": 0, "timeslot": 0, "color": "#e0ffff"},
              {"head": "月",     "dow": 1, "timeslot": 0, "color": "#e0ffff"},
              {"head": "火・朝", "dow": 2, "timeslot": 2, "color": "#ffa500"},
              {"head": "火・夜", "dow": 2, "timeslot": 3, "color": "#ffa500"},
              {"head": "水",     "dow": 3, "timeslot": 0, "color": "#e0ffff"},
              {"head": "木",     "dow": 4, "timeslot": 0, "color": "#9370db"},
              {"head": "金・朝", "dow": 5, "timeslot": 1, "color": "hotpink"},
              {"head": "金・昼", "dow": 5, "timeslot": 2, "color": "hotpink"},
              {"head": "金・夜", "dow": 5, "timeslot": 3, "color": "hotpink"},
              {"head": "土",     "dow": 6, "timeslot": 0, "color": "#e0ffff"}
    ];
    let mdow = -1;
    let colspan = 1;
    let start = 0;
    cols.forEach((c, i) => {
      if (mdow === c['dow']){
        colspan++;
        cols[i]['colspan'] = 0;
      }else{
        cols[start]['colspan'] = colspan;
        start = i;
        colspan = 1;
      }
      mdow = c['dow'];
    });
    cols[start]['colspan'] = colspan;

    let rows = [];
    rows.push(
      <tr key="head">
        { cols.map((c, i) => <th bgcolor={ c['color'] } key={ i } >{ c['head'] }</th>) }
      </tr>
    );
    const firstDayOfMonth = (new Date(year, month - 1, 1)).getDay();
    const noOfDaysOfMonth = new Date(year, month, 0).getDate();
    const noweek = Math.floor((firstDayOfMonth + noOfDaysOfMonth - 1) / 7) + 1;
    for (let row = 0; row < noweek; row++) {
      let ld = [];
      let lc = [];
      cols.forEach((c, i) => {
        let day = row * 7 - firstDayOfMonth + c['dow'] + 1;
        let date  = year + "-" + String(month).padStart(2,"0") + "-" + String(day).padStart(2,"0");
        let datec = date + "-" + String(c['timeslot']).padStart(2,"0");
        let sch = schedules[date];
        let schc = schedules[datec];
        let checks = sch ? sch['checks'] : [];
        let checksc = schc ? schc['checks'] : [];
        let className  = "date " + ((sch && checks.length !== 0) ? "open" : "close"); // イベントなしでもコメント可とした
        let classNamec = "cell";
        let colspan = c['colspan'];
        if ((new Date(year, month - 1, day)).getMonth() !== month - 1){
          day = '';datec = "";className = "";classNamec = "";
        }
        if (c['colspan'] !== 0){
          ld.push( // 日付表示行
            <td className={ className } colSpan={ colspan } data-day={ day } data-date={ date } data-checks={ JSON.stringify(checks) } key={ i } >
              <p className="day">{ day }</p>
              <p className="checks">{ checks.join('') }</p>
              <p className="comment">{ sch ? sch['comment'] : "" }</p>
            </td>
          );
        }
        lc.push( // 担当者表示行
          <td className={ classNamec } data-day={ day } data-date={ datec } data-checks={ JSON.stringify(checksc) } key={ i } >
            <p className="checks">{ checksc.join("・") }</p>
            <p className="comment">{ schc ? schc['comment'] : "" }</p>
          </td>
        );
      });
      rows.push( <tr key={ row + 'd' } >{ ld }</tr> );
      rows.push( <tr key={ row + 'c' }>{ lc }</tr> );
    }
    return(
      <table className="calender">
	<tbody>
        { rows }
	</tbody>
      </table>
    );
}

function getYearMonth(monthString)
{
  let y = parseInt(monthString.replace(/...$/, ''));
  let m = parseInt(monthString.replace(/^.{5}/, ''));
  return([y,m]);
}

function diffMonth(monthString, diff)
{
  let [y, m] = getYearMonth(monthString);
  m = m - 1 + diff;
  y += Math.floor(m / 12);
  m = (m + 12) % 12 + 1;
  return( y + '-' + String(m).padStart(2, '0'));
}

function CalenderControl()
{
    const now = new Date();
    const thisMonth = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0');
    const [month, setMonth] = useState(thisMonth);

    const seq = Array.apply(0, Array(12 + 1 + 12)).map((x, y) => y - 12);
    const months = seq.map(i => diffMonth(month, i));

    const handleButtonClick = (diff) => (ev) => {
      setMonth(diffMonth(month, diff));
    };

    const handleSelectChange = (ev) => {
      setMonth(ev.target.value);
    };

    return(
      <div className="calender-control">
        <h4>{ month.replace(/[0-9]{4}-0*/, '') + '月'}</h4>
        <div>
          <button onClick={ handleButtonClick(-1) }>&lt;</button>
          <button onClick={ handleButtonClick(+1) }>&gt;</button>
          <select value={month} onChange={ handleSelectChange }>
            { months.map(m => <option value={m} key={m}>{m}</option>) }
          </select>
        </div>
        <Calender month={ month } />
      </div>
    );
}

