import React from "react"
import logo from "./apokun_mov.gif"
import { Link } from "react-router-dom";
import { AuthButton } from './App';
import netlifyAuth from './netlifyAuth';
export function Public() {
    return(
        <header className="App-header">
        <ul id="header-menu">
          <li>
            <Link to="/about">サービス概要</Link>
          </li>
          <li>
            <button className="btn-link" onClick={netlifyAuth.signup}>アカウント作成</button>
          </li>
          <li>
            <AuthButton />
          </li>
        </ul>
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
        </header>
    )
}

export function About() {
    return(
	<header className="App-header">
        <ul id="header-menu">
          <li>
            <Link to="/public">もどる</Link>
          </li>
          <li>
            <button className="btn-link" onClick={netlifyAuth.signup}>アカウント作成</button>
          </li>
          <li>
            <AuthButton />
          </li>
        </ul>
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
        </header>
    )
}
