import React, { useEffect } from "react"
import "./App.css"
import logo from "./apokun_mov.gif"
import { Protected, Root } from './Protected';
import { Public, About } from './Public';
import netlifyAuth from './netlifyAuth';
import { BrowserRouter as Router,
	 Route,
	 Redirect,
	 withRouter } from "react-router-dom";

function App(){
    return (
      <Router>
      <Watch className="App">
        <Route path="/public" component={Public} />
        <Route path="/login" component={Login} />
        <Route path='/about' component={About}/>
        <PrivateRoute exact path="/" component={Root} onFail="/public" />
        <PrivateRoute path="/protected" component={Protected} onFail="/login" />
      </Watch>
      </Router>
    )
}

const Watch = withRouter(
  ({ className, history, children }) => {
    useEffect(() => {
      netlifyAuth.onStateChange = () => history.push('/');
    });
    return (
      <div className={className}>
        {children}
      </div>
    )
  }
);

function PrivateRoute({ component: Component, onFail, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        netlifyAuth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: onFail,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export const AuthButton = withRouter(
  ({ history }) =>
    netlifyAuth.isAuthenticated ? (
        <button className="btn btn-blue"
          onClick={() => {
            netlifyAuth.signout(() => history.push('/'));
          }}
        >
          ログアウト
        </button>
    ) : (
        <button className="btn btn-blue"
          onClick={() => {
            netlifyAuth.authenticate(() => history.push('/'));
          }}
        >
          ログイン
        </button>
    )
);

class Login extends React.Component {
  state = { redirectToReferrer: false };

  login = () => {
    netlifyAuth.authenticate(() => {
      this.setState({ redirectToReferrer: true });
    });
  };

  render() {
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    return (
	<header className="App-header">
          <div>
            <p>ログインが必要です {from.pathname}</p>
            <button className="btn btn-blue"
              onClick={this.login}>ログイン</button>
          </div>
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
        </header>
    );
  }
}

export default App
